<template>
    <a-drawer
        ref="reesterStatDrawer"
        title="Статистика по реестру объектов"
        placement="right"
        :width="drawerWidth"
        :visible="visible"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="grid gap-7 grid-cols-2 mb-7">
            <div class="stat_card">
                <div class="stat_card__head">
                    <div class="lbl">
                        {{ $t('sports.all_object') }}
                    </div>
                    <div class="g_bal">
                        6893 ед.
                    </div>
                </div>
                <div class="stat_row">
                    <div class="row_info">
                        <div class="row_label">Государственные</div>
                        <div class="row_value">4693 (75%)</div>
                    </div>
                    <a-progress 
                        :percent="75" 
                        :strokeWidth="21"
                        :show-info="false" />
                </div>
                <div class="stat_row">
                    <div class="row_info">
                        <div class="row_label">Частные</div>
                        <div class="row_value">2200 (25%)</div>
                    </div>
                    <a-progress 
                        :percent="25" 
                        strokeColor="#FE861D"
                        :strokeWidth="21"
                        :show-info="false" />
                </div>
            </div>
            <div class="stat_card">
                <div class="stat_card__head">
                    <div class="lbl">
                        Общая стоимость ремонта
                    </div>
                    <div class="g_bal">
                        315 441 млн. тенге
                    </div>
                </div>
                <div class="stat_row">
                    <div class="row_info">
                        <div class="row_label">Текущий</div>
                        <div class="row_value">126 176.4 (40%)</div>
                    </div>
                    <a-progress 
                        :percent="40" 
                        :strokeWidth="21"
                        :show-info="false" />
                </div>
                <div class="stat_row">
                    <div class="row_info">
                        <div class="row_label">Капитальный</div>
                        <div class="row_value">94632.2 (30%)</div>
                    </div>
                    <a-progress 
                        :percent="30" 
                        strokeColor="#FE861D"
                        :strokeWidth="21"
                        :show-info="false" />
                </div>
                <div class="stat_row">
                    <div class="row_info">
                        <div class="row_label">Другие</div>
                        <div class="row_value">94632.2 (30%)</div>
                    </div>
                    <a-progress 
                        :percent="30" 
                        strokeColor="#B462F2"
                        :strokeWidth="21"
                        :show-info="false" />
                </div>
            </div>
        </div>
        <div class="stat_card flex">
            <div class="stat_left">
                <apexchart
                    :key="apexchartKey"
                    type="donut" 
                    :options="chartOptions" 
                    class="chart"
                    :width="chartSize.width"
                    :height="chartSize.height"
                    :series="seriesData" />
            </div>
            <div class="stat_right pl-8">
                <div class="grid gap-4 grid-cols-3">
                    <div v-for="(item, index) in testData" :key="index" class="stat_value_item">
                        <div class="crc" :style="`background:${item.color};`"></div>
                        <div class="info">
                            <div class="name">{{ item.title }}</div>
                            <div class="val">{{ item.value }} ({{ item.percentage }}%)</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        apexchart: VueApexCharts
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        chartSize() {
            if(this.windowWidth < 1800) {
                if(this.windowWidth < 1650) {
                    if(this.windowWidth < 768) {
                        return {
                            width: '230px',
                            height: '230px'
                        }
                    } else {
                        return {
                            width: '180px',
                            height: '180px'
                        }
                    }
                } else {
                    return {
                        width: '200px',
                        height: '200px'
                    }
                }
            } else {
                return {
                    width: '230px',
                    height: '230px'
                }
            }
        },
        drawerWidth() {
            if(this.windowWidth > 975)
                return 975
            else {
                return '100%'
            }
        }
    },
    data() {
        return {
            visible: false,
            apexchartKey: Date.now(),
            seriesData: [10, 40, 20, 60, 5, 15, 20, 50, 10, 30],
            testData: [
                { title: "Стрельбище", value: 2200, percentage: 25, color: "#DDA0DD" },
                { title: "Яхт-клубы", value: 2200, percentage: 25, color: "#DDA0DD" },
                { title: "Конькобежный стадион", value: 2200, percentage: 25, color: "#800080" },
                { title: "Плоскостные сооружения", value: 2200, percentage: 25, color: "#ADD8E6" },
                { title: "Спортивные залы", value: 2200, percentage: 25, color: "#ADD8E6" },
                { title: "Ипподром с трибунами", value: 2200, percentage: 25, color: "#ADD8E6" },
                { title: "Хоккейные корты", value: 2200, percentage: 25, color: "#FFA500" },
                { title: "Теннисные корты", value: 2200, percentage: 25, color: "#FFFFE0" },
                { title: "Плавательные бассейны", value: 2200, percentage: 25, color: "#FFB6C1" },
                { title: "Встроенные спортивные залы", value: 2200, percentage: 25, color: "#A52A2A" },
                { title: "Спортивный манеж", value: 2200, percentage: 25, color: "#A52A2A" },
                { title: "Дворцы спорта", value: 2200, percentage: 25, color: "#A52A2A" },
                { title: "Спортивный комплекс", value: 2200, percentage: 25, color: "#DDA0DD" },
                { title: "База лыжная", value: 2200, percentage: 25, color: "#DDA0DD" },
                { title: "Гребная база", value: 2200, percentage: 25, color: "#800080" },
                { title: "Стрелковый тир", value: 2200, percentage: 25, color: "#ADD8E6" },
                { title: "Велотрек", value: 2200, percentage: 25, color: "#ADD8E6" },
                { title: "Трамплин для прыжков на лыжах", value: 2200, percentage: 25, color: "#ADD8E6" },
            ],
            chartOptions: {
                stroke: {
                    width: 0
                },
                legend: {
                    show: false
                },
                colors: [
                    '#9c3e18', '#bc5a28', '#d27869', '#efd89d', 
                    '#f9a947', '#9dc4ee', '#779fd6', '#6d64c4', 
                    '#9b6dd5', '#c38dec'
                ],
                dataLabels: {
                    enabled: false,
                    value: {
                        formatter(value) {
                            // eslint-disable-next-line radix
                            return `${parseInt(value)}%`
                        }
                    },
                    style: {
                        fontSize: '10px',
                        colors: ['#333']
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                chart: {
                    foreColor: '#000'
                },
                plotOptions: {
                    pie: {
                        customScale: 1,
                        startAngle: 1,
                        donut: {
                            size:'87%',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '2rem',
                                    color: '#000',
                                    offsetY: 20
                                },
                                value: {
                                    fontSize: '1.8rem',
                                    fontWeight: 300,
                                    offsetY: -14,
                                    formatter: (value) => {
                                        // eslint-disable-next-line radix
                                        return value
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: '13px', // Размер текста
                                    fontWeight: 400,
                                    label: 'Всего видов\n спортивных\nсооружений', // Многострочный текст
                                    color: '#000',
                                    formatter: () => 36, // Итоговое значение
                                },
                            }
                        }
                    },
                },
                labels: []
            }
        }
    },
    methods: {
        afterVisibleChange(vis) {

        }
    },
    mounted(){
        eventBus.$on('viewStatDrawer', () => {
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('viewStatDrawer')
    }
}
</script>

<style lang="scss" scoped>
.stat_value_item{
    display: flex;
    align-items: baseline;
    .crc{
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    .info{
        padding-left: 8px;
    }
    .name{
        font-size: 13px;
        line-height: 24px;
    }
    .val{
        opacity: 0.6;
    }
}
.stat_card{
    background: #FAFAFA;
    border-radius: 12px;
    padding: 20px 15px;
    color: #000;
    height: 100%;
    .lbl{
        opacity: 0.6;
        margin-bottom: 5px;
    }
    .g_bal{
        font-size: 18px;
        line-height: 26px;
    }
    &__head{
        padding-bottom: 15px;
        border-bottom: 1px dashed #e1e1e1;
        margin-bottom: 10px;
    }
    .stat_row{
        &:not(:last-child){
            margin-bottom: 10px;
        }
        .row_info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .row_label{
                opacity: 0.6;
            }
        }
        &::v-deep{
            .ant-progress{
                .ant-progress-inner{
                    background-color: #E3E3E3;
                }
            }
        }
    }
}
</style>