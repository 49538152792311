<template>
    <SportsFacilities />
</template>

<script>
import SportsFacilities from '@apps/SportsFacilities/index.vue'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        SportsFacilities
    }
}
</script>